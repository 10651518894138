.root {
	& p {
		font-family: var(--hdsplus-typography-body-200-font-family);
		font-size: var(--hdsplus-typography-body-200-font-size);
		line-height: var(--hdsplus-typography-body-200-line-height);
		letter-spacing: var(
			--hdsplus-typography-body-200-letter-spacing
		);

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.large p {
		@media (--large) {
			font-family: var(--hdsplus-typography-body-400-font-family);
			font-size: var(--hdsplus-typography-body-400-font-size);
			line-height: var(--hdsplus-typography-body-400-line-height);
			letter-spacing: var(
				--hdsplus-typography-body-400-letter-spacing
			);
		}
	}

	&.medium p {
		@media (--large) {
			font-family: var(--hdsplus-typography-body-300-font-family);
			font-size: var(--hdsplus-typography-body-300-font-size);
			line-height: var(--hdsplus-typography-body-300-line-height);
			letter-spacing: var(
				--hdsplus-typography-body-300-letter-spacing
			);
		}
	}
}